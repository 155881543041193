@import '../../theme';

.row {
  padding: $spaceXS $spaceXS $spaceXXS $spaceXS;
}

.queryFormatContainer {
  @include flex-row;
  align-items: baseline;
  justify-content: space-between;
}

.queryFormatLabel {
  display: inline;
}

.selectWrapper {
}

.selectWrapper .selectBox {
  border-radius: $borderRadiusFrames;
}

.col {
  @media screen and (max-width: 576px) {
    margin: $spaceXS 0;
  }
}
