// Thanks to https://codepen.io/anthonyLukes/pen/DLBeE for guidance on structure for

@import '../../theme';

.fixedPosition {
  flex: none;
}

.flexibleContent {
  flex: auto;
  width: 100%;
  position: relative; /* need this to position inner content */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @media screen and (min-width: 600px) {
    padding: 0 $spaceLG $spaceLG $spaceLG;
  }
  @media screen and (max-width: 600px) {
    padding: 0 $spaceSM $spaceSM $spaceSM;
  }
  padding: 0 $spaceLG $spaceLG $spaceLG;
  margin-top: $spaceXS;
}
