@import '../../theme';

.card {
  @media screen and (min-width: 600px) {
    margin: $spaceLG;
  }
  @media screen and (max-width: 600px) {
    margin: $spaceLG $spaceSM;
  }
  position: relative;
  width: 300px;
  height: 382px;
  border-radius: $borderRadiusFrames;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    opacity: 1;
    border-radius: $borderRadiusFrames;
    @media screen and (max-width: 600px) {
      background: #fff;
      transform: scale(1.1);
      box-shadow: 3px 3px 4px 3px rgba(204, 204, 204, 0.6);
    }
    @media screen and (min-width: 600px) {
      transition: all 0.3s ease-in-out;
    }
  }

  &:hover::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;

    @media screen and (min-width: 600px) {
      background-color: $colorLightBackground;
      transform: scale(1.1);
    }
  }
}

.contentBackground {
  position: absolute;
  width: 300px;
  z-index: 1;
}
.grid {
  display: grid;
  grid-gap: $spaceXS;
  grid-template-columns: 88px 1fr 88px;
  grid-template-rows: 200px 48px 20px 20px 30px 30px;
}

.image {
  border-radius: $borderRadiusFrames;
  margin: 0;
  background-image: url('https://cdn-images-1.medium.com/max/250/1*u3m_MS6jKpFb-9j8a2IZmw.jpeg');
  background-size: cover;
  width: 300px;
  height: 200px;
}

.link {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  grid-column: 1 / -1;
}

.title {
  height: 48px;
  color: $colorText;
  padding: 0;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.5em;
  max-height: 3.8em;
}

.publication {
  padding: 0;
  font-size: 0.9rem;
  color: $colorText;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  grid-column: 1 / -1;
}

.tags {
  padding: 0;
  font-size: 0.9rem;
  color: $colorText;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  grid-column: 1 / -1;
}

.footer {
  padding: $spaceMD $spaceXS $spaceXS 0;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 30% 40% 30%;
}

.sourceIcon {
  margin-right: $spaceXXS;
}

.icon {
  height: 16px;
  width: 16px;
  margin-right: $spaceXS;
  color: $colorIcon;
}
.metadata {
  color: $colorLightText;
  font-size: $textXS;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-child(5) {
    justify-content: flex-start;
  }
  &:nth-child(7) {
    justify-content: flex-end;
  }
}

.more {
  grid-column: 1/-1;
  display: flex;
  justify-content: space-between;
}
