@import '../../theme';

.menuWrapper {
  margin: 0 $spaceMD 0 auto;
  flex: none;
}
.headerMenuFrame {
  margin: 0 $spaceBaseUnit;
}

.signInButton {
  padding: $spaceXXS $spaceXS !important;
  border-radius: $borderRadiusControls;
  margin-left: $spaceXXS;
  margin-right: $spaceXXS;
}

.signInLabel {
  display: inline;
  padding-left: $spaceXXS;
  padding-right: $spaceXXS;
}

:export {
  menuBreakpoint: 700;
}
