@import '../../theme';

.container {
  z-index: 10;
}

.row {
  margin: $spaceXXS $spaceXS $spaceXXS $spaceXS;
}

.filterSortWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.statsWrapper {
  margin: 0 $spaceXXS 0 $spaceXS;
}
.filterButtonWrapper {
  margin: 0 $spaceXXS 0 $spaceXXS;
}
.sortWrapper {
  margin: 0 $spaceXXS 0 $spaceXS;
  width: 270px;
}

