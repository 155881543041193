@import '../../theme';

.button{
  padding: $spaceXXS !important;
  margin-left: $spaceXXS;
  margin-right: $spaceXXS;
}

.buttonIcon {
  display: flex;
  align-items: center;
  color: $colorIcon;
  &:hover {
    color: $colorPrimary;
  }
}
