@import '../../theme';

.row {
  padding: $spaceLG $spaceSM;
}
.avatarBorder {
  margin-left: auto;
  @media screen and (min-width: 768px) {
    margin-right: $spaceSM;
  }
  @media screen and (max-width: 768px) {
    margin-right: auto;
  }
  height: 158px;
  width: 158px;
  border: 1px solid $colorPrimary;
  border-radius: 50%;
}

.card {
  border-radius: $borderRadiusFrames;
  @media screen and (min-width: 768px) {
    margin: $spaceLG $spaceXL $spaceLG $spaceXL;
  }
  @media screen and (max-width: 768px) {
    margin: $spaceLG $spaceSM $spaceLG $spaceSM;
  }
  box-shadow: 3px 3px 4px 3px rgba(204, 204, 204, 0.6);
}

.name {
  @media screen and (min-width: 768px) {
    text-align: left;
  }
  @media screen and (max-width: 768px) {
    text-align: center;
  }
}

.attributes {
  @media screen and (min-width: 768px) {
    margin-left: 0;
  }
  @media screen and (max-width: 768px) {
    margin-left: $spaceXL;
  }
}

.noMarginBottom {
  margin-bottom: 0;
}

.smallMarginBottom {
  margin-bottom: $spaceXS;
}

.bookmarklet {
  margin-right: $spaceSM;
}
.attributeValue {
  margin-top: $spaceXXS;
}

.webhook {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: $spaceMD;
}

.buttonLabel {
  display: inline;
  padding-left: $spaceXXS;
  padding-right: $spaceXXS;
}

.buttonFrame {
  padding: $spaceXXS $spaceXS !important;
  border-radius: $borderRadiusControls;
  margin-left: 0;
  margin-right: $spaceXXS;
}
