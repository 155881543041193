@import '../../theme';

.header {
  @include flex-row;
  align-items: center;
  //justify-content: start;
  height: 60px;
  border-bottom: solid 1px $colorBorder;
}

.logo {
  margin: 0 $spaceMD;
  flex: none;
}

.searchInputContainer {
  flex: auto;
  max-width: 600px;
  margin: 0 $spaceMD 0 0;
}
.searchInputContainer .searchInput {
  border-radius: $borderRadiusControls;
  background-color: $colorLightBackground;
}

.clearSearch {
  position: absolute;
  top: -4px;
  margin-left: -20px;
}
