@mixin spinner($size) {
  width: $size;
  height: $size;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
}

@keyframes contentAnimation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin spinnerContent($size, $beforeAnimation, $afterAnimation) {
  animation: contentAnimation 1s linear infinite;
  &::before {
    border-radius: 50%;
    content: ' ';
    width: calc(#{$size} / 2);
    height: calc(#{$size} / 2);
    display: inline-block;
    box-sizing: border-box;
    background-color: #0fd6ff;
    position: absolute;
    top: 0;
    left: 0;
    animation: $beforeAnimation 1s ease-in-out infinite;
  }
  &::after {
    border-radius: 50%;
    content: ' ';
    width: calc(#{$size} / 2);
    height: calc(#{$size} / 2);
    display: inline-block;
    box-sizing: border-box;
    background-color: #eb68a1;
    position: absolute;
    bottom: 0;
    right: 0;
    animation: $afterAnimation 1s ease-in-out infinite;
  }
}

@mixin beforeAnimation($size) {
  0% {
    transform: translate(calc(#{$size}/ -8), calc(#{$size}/ -8));
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(calc(#{$size}/ -8), calc(#{$size}/ -8));
  }
}
@mixin afterAnimation($size) {
  0% {
    transform: translate(calc(#{$size}/ -8), calc(#{$size}/ 8));
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(calc(#{$size}/ -8), calc(#{$size}/ 8));
  }
}

@keyframes smallSpinnerBefore {
  @include beforeAnimation(24);
}
@keyframes smallSpinnerAfter {
  @include afterAnimation(24);
}

.smallSpinner {
  @include spinner(24px);
}
.smallSpinnerContent {
  @include spinnerContent(24px, smallSpinnerBefore, smallSpinnerAfter);
}

.fullPageSpinner {
  @include spinner(150px);
}
.fullPageSpinnerContent {
  @include spinnerContent(150px, smallSpinnerBefore, smallSpinnerAfter);
}

.fullPageContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.fullPageCenterScreen {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -50px;
  margin-top: -50px;
}
