@import '../../theme';

.frame {
  position: absolute; //this was needed and wasn't in original post
  @include flex-column;
  height: 100%;
  width: 100%;
  background: $appBackgroundColor;
}

