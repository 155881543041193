@import '../../theme';

.message {
  margin-top: 0;
}

.dialog {
  border-radius: $borderRadiusFrames;
}

.button {
  margin: 0 $spaceXXS;
}
