@import '../../theme';

.form {
  margin-right: $spaceBaseUnit;
}

.url{
  border-radius: $borderRadiusControls;
}

.tags {
  height: 150px;
}
